import { urlHasValidParameters } from "../helpers/url-params";
import { form, tracking } from "../helpers/names";
import { isTestSession } from "../globals";
import { triggerPageView, triggerEvent } from "../tracking/helper";
import { waitTealiumReady } from "../helpers/waitTealiumReady";

declare let additionalSearchParams : any;

export function setupSearchFormPage() {
  console.log("setupSearchFormPage");
  // do as if the page is a single page application / SPA, since tui.com has its own strange utag setting
  // make sure that the page where this component hosted has utag_cfg_ovrd = {noview: true};
  // FOR NOW, remove, since JAMStack triggers this already 
  // waitTealiumReady().then(() => triggerPageView("flug_home"));
  /*
    Subscribe to flight search form and listen for searchOnd data.
    Then forward searchOnd date to flight result list.
  */
  tuiCottonBall.subscribe(form, "*", "search", (c, s, e, data) => {
    console.log("Integration Layer: Received searchOnd from search form: ", data);
    console.log("Redirect to flugauswahl", data);
    tuiCottonBall.publish(tracking, "*", "search", data);
    
    let newUrl = "/flug/flugauswahl/?" + data.asQuery;

    const hostname = window.location.hostname;
    if(hostname === 'dev.cloud.tfm.tui.com' || hostname === 'staging.cloud.tfm.tui.com') {
      // use realtive links on dev and staging to link to pages like /tuicom/flug/flugauswahl/ correctly
      newUrl = "./flugauswahl/?" + data.asQuery;
    }

    if (typeof additionalSearchParams !== 'undefined' && additionalSearchParams) {
      newUrl += additionalSearchParams;
    }
    if (isTestSession) {
      newUrl += "&test=1";
    }
    // wait for tracking link event to be sent
    setTimeout(() => {
      window.location.href = newUrl;
    }, 10);
    // forward searchOnd to search result list
    //- console.log("Try to publish to search results", data);
    // - tuiCottonBall.publish("tui-flights-search-results", "tfm", "search", data);
  });

  // Search-Form Params Order:
  // 1. See if web-component has setting
  // 2. Wait for cottonball event set-params
  // Integrity layer can then set params if URL is valid.
  const [valid, params] = urlHasValidParameters();
  if (valid) {
    // valid or not valid, still fill the form (for i.e. valid origin and dest but wrong date)
    // hydrate compact search from URL params.
    // BUT NOT redirect - only redirect when explicitly clicked "flüge finden"
    tuiCottonBall.publish(form, "*", "init", { initialValues: params });
  } else {
    tuiCottonBall.publish(form, "*", "init", {});
  }
}