import setupTfmGlobalTracking from "./tfm";
import { waitTealiumReady } from "../helpers/waitTealiumReady";

export default function setupTracking() {
  if (!window.utag_data) {
    window.utag_data = {};
  }
  waitTealiumReady().then(() => {
    setupTfmGlobalTracking();
  });
}