import { SearchQueryAPI } from "@tui-internal/@tui-internal/tfm-types-and-helpers";
import moment from "moment";
import { booking } from "../helpers/names";
import { extendUtagData, setUtagDataForFlight, triggerPageView } from "./helper";
import { SCOPE } from "./tfm";
import { waitTealiumReady } from "../helpers/waitTealiumReady";

function getFlexOptionPrice(flexOption: { passengers: any[]; }) {
  return flexOption.passengers.reduce((sum: number, pax: { productPrice: number; }) => sum + pax.productPrice, 0);
}

export function setupBU3Tracking() {
  /**
   * Create cotton ball subscriber before telium ready, but wrap the actions inside in waitTealiumReady;
   * Cotton Ball can then already subscribe to future events and process them, even when teailum is not yet loaded, and queue this after tealium ready.
   */
  tuiCottonBall.subscribe(booking, SCOPE, "flight-confirmation-page-view", (c, s, e, payload) => {
    waitTealiumReady().then(() => {
      console.log("tealium available, tracking confirmation page..");
      console.log(payload);
      const { bookingResult, flightCombination } = payload;
      const { bookingInfo, bookingDetails } = bookingResult; // bookingInfo == bookingStatus mostly, bookingDetails == bookingRequest

      if (flightCombination) {
        try {
          const query = {
            origin: flightCombination.origin,
            destination: flightCombination.destination,
            depDate: moment(flightCombination.outbound.departureTime).format("YYYY-MM-DD"),
            retDate: flightCombination.inbound
              ? moment(flightCombination.inbound.departureTime).format("YYYY-MM-DD")
              : null,
          } as SearchQueryAPI;
          setUtagDataForFlight({ ...query, flight: flightCombination });
        } catch (e) {
          console.error(e);
        }
      }

      if (bookingResult.bookingInfo.recordLocator) {
        setUtagDataForFlight({ order_id: bookingResult.bookingInfo.recordLocator });
      }

      // in case errors, at least trigger flug_bu3 with no info
      if (!bookingInfo || !bookingDetails) {
        extendUtagData({ error: "No book information" });
        triggerPageView("flug_bu3");
        return;
      }

      if (bookingDetails && bookingDetails.voucherInfo) {
        const vouchers = bookingDetails.voucherInfo;

        let promoName = "";
        let promoTotal = 0;
        vouchers.forEach((voucherInfo: any) => {
          if (voucherInfo.type === "service") {
            promoName += "******_";
          } else {
            promoName += voucherInfo.voucher + "_";
          }

          promoTotal += voucherInfo.value;
        });

        extendUtagData({
          promocode_name: promoName,
          promocode_total: promoTotal,
          promocode_value: promoTotal / bookingResult.paxAmount,
        });
      }

      /** WARENKORB TRACKING MANUAL:
       *
       * Zum Warenkorb-Tracking Flug:
       * product_name=<DepStart>-<DestStart>-<DepReturn>-<DestReturn>
       * product_code=<CarrierStart>-<CarrierReturn>-<flightSource>
       * product_type='flight'
       * product_quantity= <Anzahl Paxe ohne Infants>
       * product_price=<arithmetischer Mittelwert des Flugpreis pro Pax ohne Infants>

       * Warenkorb-Tracking Infants:
       * product_name='Infant'
       * product_code='Inf'-<flightSource>
       * product_type='inf-book'
       * product_quantity= <Anzahl Infants>
       * product_price=<Preis pro Infant>

       * Ab hier neu für Warenkorb-Tracking Gepäck:

       * product_name=<Bezeichnung der Gepäckart>
       * product_code=<Detailcode des ausgewählten Gepäckarten>-<flightSource>
       * product_type='bag-book'
       * product_quantity= <Anzahl gebuchter Gepäckstücke der aktuellen Gepäckart>
       * product_price=<Preis pro gebuchten Gepäckstücke der aktuellen Gepäckart>

       * Wichtig: Wurden in der Buchung Gepäcke verschiedener Gepäckarten gebucht, so ist für jeden Gepäckart eine eigene Zeile einzufügen.

       * Anmerkung: Dem product_type wurde ein Postix hinzugefügt, das den Buchungsort kennzeichnet. Aktuell ist dies nur auf der Buchungsstrecke (-book) möglich. Zukünftig sind aber auch der WebCheckIn (-wcki) oder eine Extras-Buchungsstrecke (-extras) möglich.

       * Beispiele:
       * product_name='1. piece of luggage with 20 kg' oder 'additional piece of luggage with 25kg'
       * product_code='BA20' oder 'BX25'

       * Beispiel der Übergabe als Array bei Flug und Gepäckstücken für Hin- und Rückflug:
       * product_name=('HAJ-PMI-PMI-HAJ, 1. piece of luggage with 20 kg')
       * product_code =('X3-X3-NSK, BA20')
       * product_type = ('flight, bag-book')
       * product_quantity=('1, 2')
       * product_price = ('119.98, 25')
       */


      extendUtagData({
        tui_tipp: flightCombination?.promoted,
        top_deal: flightCombination?.topDeal,
        flight_source: bookingResult?.query?.source,
        destination_country: payload?.destination_country,
        user_email_sha256: payload?.user_email_sha256,
      });

      if (flightCombination?.cabinClasses) {
        extendUtagData({
          cabin_class: flightCombination.cabinClasses.toString(),
        });
      }

      // WARENKORB TRACKING, Only on bu3

      const product_name = [];
      const product_code = [];
      const product_type = [];
      const product_quantity = [];
      const product_price = [];

      // Tracking Flight Product
      let allSegments = flightCombination.outbound.segments;
      if (flightCombination.inbound) allSegments = allSegments.concat(flightCombination.inbound.segments);
      const flight_names: string[] = [];
      const flight_codes: string[] = [];
      allSegments.forEach((segment: any) => {
        flight_names.push(segment.origin + "-" + segment.destination);
        flight_codes.push(segment.carrier);
      });
      product_name.push(flight_names.join("-"));
      product_code.push(flight_codes.join("-") + "-" + flightCombination.source);
      product_type.push("flight");
      const quantityAdtChd = bookingResult.query.adult + bookingResult.query.child;
      product_quantity.push(quantityAdtChd);

      // google will always try to multiply quantity with price, so this is a hack by tracking people (Peter Inhulsen)
      let sumPrice = 0;
      Object.keys(flightCombination.priceDetail.perPaxType).forEach((paxType) => {
        if (paxType === "INF") return;
        const pax = flightCombination.priceDetail.perPaxType[paxType];
        // price is changed if seat or bag is booked. (originally using brutto, which is mapped to price)
        console.log("price", pax);
        if (paxType === "ADT") {
          const priceForAdt = Number.parseFloat((pax.netto + pax.tax + pax.markup).toFixed(2));
          sumPrice += priceForAdt * bookingResult.query.adult;
        } else if (paxType === "CHD") {
          const priceForChd = Number.parseFloat((pax.netto + pax.tax + pax.markup).toFixed(2));
          sumPrice += priceForChd * bookingResult.query.child;
        }
      });
      const avgPrice = sumPrice / quantityAdtChd;
      product_price.push(avgPrice);

      // Tracking Passengers Infant, that is not included up there

      if (flightCombination.priceDetail.perPaxType.INF) {
        product_name.push("Infant");
        product_code.push("Inf-" + flightCombination.source);
        product_type.push("inf-book");
        product_quantity.push(bookingResult.query.infant);
        product_price.push(flightCombination.pricePerInfant);
      }

      // Tracking TFA Seat Sitzplatz
      const seatFlights = bookingDetails.tfa && bookingDetails.tfa.seat && bookingDetails.tfa.seat.flights;
      if (Array.isArray(seatFlights) && seatFlights.length > 0) {
        const allPassengers = seatFlights.reduce((result, f) => result.concat(f.passengers), []);
        const allSeats = allPassengers
          .reduce((result: [], p: any) => result.concat(p.seat), [])
          .filter((s: any) => s !== null);
        allSeats.forEach((seat: any) => {
          product_name.push(seat.seatCategory);
          product_code.push(seat.row + seat.column);
          product_type.push("seat-book");
          product_quantity.push(1);
          product_price.push(seat.price.amount);
        });
      }

      // Tracking TFA SSR Bag
      const ssrFlights = bookingDetails.tfa && bookingDetails.tfa.ssr && bookingDetails.tfa.ssr.flights;
      if (Array.isArray(ssrFlights) && ssrFlights.length > 0) {
        const allPassengers = ssrFlights.reduce((result, f) => result.concat(f.passengers), []); // flatten
        const allBags = allPassengers
          .reduce((result: [], p: any) => result.concat(p.selectedSsr), [])
          .filter((s: any) => s !== null);
        const bags: any = {};

        allBags.forEach((bag: any) => {
          if (bags[bag.ssrCode] && bags[bag.ssrCode].qty > 0) {
            bags[bag.ssrCode].qty++;
          } else {
            bags[bag.ssrCode] = {
              name: bag.description || bag.shortDescription,
              qty: 1,
              code: bag.ssrCode,
              price: bag.price,
            };
          }
        });
        Object.keys(bags).forEach((bagKey: string) => {
          const bag = bags[bagKey];
          product_name.push(bag.name);
          product_code.push(bag.code);
          product_type.push("bag-book");
          product_quantity.push(bag.qty);
          product_price.push(bag.price);
        });
      }

      // Tracking service packages
      if (bookingResult?.servicePackages && bookingDetails?.servicePackages) {
        const servicePackagesList: any[] = bookingResult?.servicePackages;
        const passengersLength = bookingDetails?.servicePackages.passengers.length;
        const selectedServicePackageId = bookingDetails?.servicePackages.passengers[0]?.servicePackageId;
        const selectedServicePackage = servicePackagesList.find(spItem => spItem.id === selectedServicePackageId);

        product_name.push(`servicepackages-${selectedServicePackage.name}`);
        product_code.push(`SP-${selectedServicePackage.name}`);
        product_type.push("service-book");
        product_quantity.push(passengersLength);
        product_price.push(selectedServicePackage.price.value);

      }

      // Tracking flex options
      if (bookingDetails?.flexOptions?.selectedFlexOptions) {
        const selectedFlexOptions: any[] = bookingDetails?.flexOptions.selectedFlexOptions;
        selectedFlexOptions.forEach(flexOption => {
          if (flexOption.type === "CANCEL") {
            product_name.push("flexoptions-FlexStorno");
            product_code.push("FlexStorno");
          } else if (flexOption.type === "MODIFY") {
            product_name.push(`flexoptions-${flexOption.name}`);
            product_code.push(flexOption.name);
          }
          product_type.push("flex-book");
          product_quantity.push(flexOption.passengers.length);
          const price = getFlexOptionPrice(flexOption);
          product_price.push(price);
        });
      }

      // FINAL set
      extendUtagData({
        product_name: product_name.join(),
        product_code: product_code.join(),
        product_type: product_type.join(),
        product_quantity: product_quantity.join(),
        product_price: product_price.join(),
      });

      // product_id is required for tracking
      if (product_name.length > 0) {
        extendUtagData({ product_id: product_name.join() });
      } else {
        // should not happen here. A booking always has at least one product, flight
        extendUtagData({ product_id: "flug_nbso" });
      }

      // NBSO-3382: track payment method
      let paymentMethod = "";
      if (bookingDetails?.paymentMethod) {
        paymentMethod = bookingDetails.paymentMethod.type;
      }
      extendUtagData({ book_payment_method: paymentMethod });

      triggerPageView("flug_bu3");
    });
  });

  // if (data.tfaData) {
  // NBSO-2618: if seat data available, concat with the default flight products like flight or infant
  // extendUtagDataTFA(data.tfaData);
  // }

  //   trackData('page_name', 'flug_bu3', true, true);
  // triggerPageView("flug_bu3"); // this will also send tfaData and price as well.
  // });
}
