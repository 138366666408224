import { getFilterData, getSearchQuery, getStateFromUrl, urlHasValidParameters } from "../helpers/url-params";
import { form, filter, results, tracking } from "../helpers/names";
import { triggerPageView, setUtagDataForFlight } from "../tracking/helper";
import { SearchQuery, SearchQueryAPI } from "@tui-internal/tfm-types-and-helpers";
import { waitTealiumReady } from "../helpers/waitTealiumReady";
import { setupPT3Tracking } from "../tracking/page-pt3";

let currentState: SearchResultsState = { search: {} };
let currentUrlParams = "";
let baseUrl = window.location.origin + window.location.pathname;

export function setupSearchResultsPage() {
  console.log("setupSearchResultsPage");
  // utag_cfg_ovrd = {noview: true} is needed
  waitTealiumReady().then(() => {
    triggerPageView("flug_pt3");
    setupPT3Tracking();
  });

  const urlParams = new URLSearchParams(window.location.search);
  currentState = getStateFromUrl(urlParams.toString());

  const depDate = urlParams.get("depDate");
  const retDate = urlParams.get("retDate");
  // only check maturity and duration params if no flight dates are given
  if (!depDate && !retDate) {
    const maturity = urlParams.get("maturity");
    const duration = urlParams.get("duration");

    // roundtrip, maturity and duration given
    if (duration && maturity) {
      const newDepDate = getDepDateByMaturity(parseInt(maturity));
      currentState.search.depDate = newDepDate;
      currentState.search.retDate = getRetDateByDuration(newDepDate, parseInt(duration));
      // oneway, only maturity given
    } else if (maturity) {
      currentState.search.depDate = getDepDateByMaturity(parseInt(maturity));
    }

    updateUrl(false, true);
  }

  // called only once - full of flaw, can be replaced with URLSearchParams maybe?
  const [valid, params] = urlHasValidParameters();
  // valid or not valid, still fill form with partial params
  tuiCottonBall.publish(form, "*", "init", { initialValues: JSON.parse(JSON.stringify(params)) });

  // TODO: not sure if should still use valid check
  if (valid) {
    // tuiCottonBall.publish(tracking, "*", "set")
    setUtagDataForFlight(params);

    if (
      !validDate(currentState.search.depDate) ||
      (currentState.search.retDate && !validDate(currentState.search.retDate))
    ) {
      console.error("Invalid date detected. Abort cotton ball event.", currentState);
      return;
    }

    // console.log("CB IL found valid params in URL. Setup form");
    // tuiCottonBall.publish(form, "*", "collapse", true);
    document.getElementsByTagName(form)[0].setAttribute("collapsed", "true"); // only affects when in mobile
    tuiCottonBall.publish(form, "*", "disable", {});

    tuiCottonBall.publish(filter, "*", "initial-value", currentState.filter);

    tuiCottonBall.publish(
      results,
      "*",
      "search",
      Object.assign({}, currentState.search, { test: currentState.test, filter: currentState.filter })
    );
  }

  tuiCottonBall.subscribe(form, "*", "search", (c, scope, e, data: any & SearchQueryAPI & { asQuery: string }) => {
    console.log("CB IL receive event from search-form - search with params", JSON.parse(JSON.stringify(data)));

    if (!validDate(data.depDate) || (data.retDate && !validDate(data.retDate))) {
      console.error("Invalid date detected. Abort cotton ball event.", data);
      return;
    }

    tuiCottonBall.publish(tracking, "*", "search", data);

    const searchQueryData = getSearchQuery(data);
    currentState = Object.assign({}, currentState, { search: searchQueryData });
    updateUrl();

    tuiCottonBall.publish(results, scope, "remove-cache", data);
    tuiCottonBall.publish(results, scope, "search", JSON.parse(JSON.stringify(data)));
    tuiCottonBall.publish(filter, scope, "reset", {});
    tuiCottonBall.publish(form, scope, "disable", {});
    // tuiCottonBall.publish(form, scope, "collapse", true);
    document.getElementsByTagName("tui-flights-search-form")[0].setAttribute("collapsed", "true");
  });

  tuiCottonBall.subscribe(results, "*", "filter-data", (c, scope, e, payload) => {
    // console.log("CB IL receive from search-results - filter-data", data);
    tuiCottonBall.publish(filter, scope, "filter-data", payload);
  });

  tuiCottonBall.subscribe(results, "*", "search-done", (c, scope, e, data) => {
    // console.log("CB IL receive from search-results - search-done, send to filter, enable.");

    const hasScrollYParams = currentState.hasOwnProperty("scrollY") && currentState.scrollY;
    const isFromBackButtonFromBooking = hasScrollYParams;
    if (isFromBackButtonFromBooking) {
      window.scrollTo(0, +currentState.scrollY);
    }

    tuiCottonBall.publish(tracking, scope, "search-done", data);
    tuiCottonBall.publish(filter, scope, "enable", {});
    tuiCottonBall.publish(form, scope, "enable", {});
  });

  tuiCottonBall.subscribe(results, "*", "reset-filter", (c, scope, e, data) => {
    tuiCottonBall.publish(filter, scope, "reset", {});
    tuiCottonBall.publish(filter, scope, "enable", {});
    // url must be changed to reset also
  });

  tuiCottonBall.subscribe(filter, "*", "filter", (c, scope, e, data) => {
    // console.log("CB IL receive from filter FILTER, CB filter send to search results");
    tuiCottonBall.publish(results, scope, "filter", data);
    const filterState = getFilterData(JSON.parse(JSON.stringify(data))); // remove __observer__ with JSON tricks
    currentState = Object.assign({}, currentState, { filter: filterState });
    updateUrl();
  });

  tuiCottonBall.subscribe(
    results,
    "*",
    "select-flight",
    (c, scope, e, data: { flight: any; combiHashCode: string }) => {
      tuiCottonBall.publish(tracking, scope, "select-flight", data);
      const href = "../flugbuchung";
      setTimeout(() => {
        const updatedDeeplinkParams: {
          [key: string]: string
        } = { 
          origin: currentState.search.origin ?? "", 
          destination: currentState.search.destination ?? "", 
          combiHash: data.combiHashCode
        };

        if (currentState.test) {
          updatedDeeplinkParams.test = "1";
        }

        updateUrl(true);
        window.location.href = `${href}/?${new URLSearchParams(updatedDeeplinkParams).toString()}`;
      }, 50);
    }
  );

  tuiCottonBall.subscribe(
    results,
    "*",
    "flight-details",
    (c, scope, e, data: { flight: any; combiHashCode: string }) => {
      tuiCottonBall.publish(tracking, scope, "flight-details", data);
    }
  );

  // reset filter-box when a new search is triggered by the flexible-flights-bar
  tuiCottonBall.subscribe(results, "*", "offset-search", (c, scope, e, data: { offset: number; query: any }) => {
    let searchQueryData = getSearchQuery(data.query as any);
    // append offset index to url, e.g. -1 for offset-search one day before
    searchQueryData = { ...searchQueryData, offset: data.offset, flex: true };
    currentState = Object.assign({}, currentState, { search: searchQueryData });
    updateUrl();
    tuiCottonBall.publish(filter, scope, "reset", {});
  });

  tuiCottonBall.subscribe(results, "*", "flex-flights-shown", (c, scope, e, data: { data: any }) => {
    tuiCottonBall.publish(tracking, scope, "flex-flights-shown", data);
  });

  tuiCottonBall.subscribe(results, "*", "flex-flight-select", (c, scope, e, data: { depDate: any, retDate: any }) => {
    currentState.search.depDate = data.depDate;
    if(data.retDate) currentState.search.retDate = data.retDate;
    updateUrl();
    tuiCottonBall.publish(form, "*", "init", { initialValues: currentState.search });
    tuiCottonBall.publish(
      results,
      "*",
      "search",
      Object.assign({}, currentState.search, { test: currentState.test, filter: currentState.filter })
    );
    tuiCottonBall.publish(filter, scope, "reset", {});
    tuiCottonBall.publish(tracking, scope, "flex-flight-select", data);
  });

  tuiCottonBall.subscribe(filter, "*", "filter-used", (c, scope, e, data: any) => {
    tuiCottonBall.publish(tracking, scope, "filter-used", data);
  });
}

export function updateUrl(withScroll = false, replaceState = true) {
  // let newParams = Object.assign({}, currentState.search, currentState.filter);
  let newParams = Object.assign({}, currentState.search, currentState.filter);
  if (withScroll) {
    newParams = Object.assign({}, newParams, { scrollY: window.scrollY });
  }
  if (currentState.test) {
    newParams = Object.assign({}, newParams, { test: 1 });
  }
  if (currentState.tracking) {
    newParams = Object.assign({}, newParams, currentState.tracking);
  }
  currentUrlParams = new URLSearchParams(newParams).toString();
  console.log("updateUrl to with params "); // + currentUrlParams);
  let updatedUrl = baseUrl + "?" + currentUrlParams;
  // if (window.location.href.indexOf("carriers") > -1) {
  //   let carriersMatch = window.location.href.match(/\&carriers=([A-Z0-9,]+)&?/);
  //   let carriers = carriersMatch && carriersMatch[1] ? carriersMatch[1] : "";
  //   updatedUrl += "&carriers=" + carriers;
  // }
  // since we are already on the correct page, do not "redirect", only push state.
  if (replaceState) {
    return window.history.replaceState(currentState, document.title, updatedUrl);
  }
  return window.history.pushState(currentState, document.title, updatedUrl);
}

/**
 * @param maturity Number of days between today and the desired outbound flight date.
 */
const getDepDateByMaturity = (maturity: number) => {
  const date = new Date();
  date.setDate(date.getDate() + maturity);
  const newDepDate = date.toISOString().split("T")[0];
  console.log(`Setting depDate ${maturity} days in future to: ${newDepDate}`);
  return newDepDate;
};

/**
 * @param depDate Departure date in iso string. E.g. 2028-11-23
 * @param duration Duration of full days to stay at destination.
 */
const getRetDateByDuration = (depDate: string, duration: number) => {
  const date = new Date(depDate);
  date.setDate(date.getDate() + duration);
  const newRetDate = date.toISOString().split("T")[0];
  console.log(`Setting retDate ${duration} days after departure to: ${newRetDate}`);
  return newRetDate;
};

/**
 * Checks if the given input is a valid string date.
 * E.g. valid form: "2020-01-12","1988-12-31"
 */
const validDate = (date: any) => {
  if (typeof date !== "string") return false;

  const dateSplit = date.split("-");
  const year = parseInt(dateSplit[0]);
  const month = parseInt(dateSplit[1]);
  const day = parseInt(dateSplit[2]);

  if (!(year >= 1000 && year <= 9999)) return false;
  if (!(month >= 1 && month <= 12)) return false;
  if (!(day >= 1 && day <= 31)) return false;

  return true;
};

/**
 * Describes all attributes that defines the state of the search results page.
 */
export interface SearchResultsState {
  search: SearchQueryKeys; 
  filter?: FilterKeys; 
  scrollY?: any; 
  test?: boolean; 
  tracking?: any;
}

export interface SearchQueryKeys {
  journeyType?: string;
  origin?: string;
  destination?: string;
  depDate?: string;
  retDate?: string;
  adult?: number;
  child?: number;
  infant?: number;
  flex?: string;
  [x: string]: any;
}

export interface FilterKeys {
  airports?: string;
  baggageIncludedOnly?: boolean;
  rebookable?: boolean;
  cabinClasses?: string[];
  carriers?: string[];
  inboundTimeRange?: number[];
  inboundMaxTravelTime?: number;
  maxStops?: number;
  outboundTimeRange?: number[];
  outboundMaxTravelTime?: number;
  priceRange?: string;
  [x: string]: any;
}

export interface TrackingKeys {
  coopid?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
  [x: string]: any;
}