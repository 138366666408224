import moment from "moment";
import { FlightCombination, SearchQueryAPI } from "@tui-internal/tfm-types-and-helpers";
import { triggerEvent, setUtagDataForFlight, extendUtagData, triggerPageView } from "./helper";
import { tracking } from "../helpers/names";
import { SCOPE } from "./tfm";
/**
 * Create tracking-relevant subscriber for flug_pt3 page (search results component page)
 */
export function setupPT3Tracking() {
  tuiCottonBall.subscribe(tracking, SCOPE, "search-done", (c, s, e, data) => {
    setUtagDataForFlight(data);
    if (data.resultsCount) {
      extendUtagData({ flight_results_count: data.resultsCount });
      triggerEvent(data.resultsCount, "", "FlightSearchResultsCount", "feature", true);
    }
  });

  tuiCottonBall.subscribe("tui-flights-search-results", "*", "hotel-available", () => {
    console.log('hotel available subscribed');
    utag.link({
      tealium_event: "link",
      link_category: "fo_offer2package",    
      link_action: "available",
      noninteraction: "true",
      });
  });

  tuiCottonBall.subscribe("tui-flights-search-results", "*", "hotel-available-click", () => {
    console.log('hotel clicked');
    utag.link({
      tealium_event: "link",
      link_category: "fo_offer2package",    
      link_action: "click"
      });
  });

  // Triggered when details is opened in FlightCard.
  // previous name was "FlightFetch"
  tuiCottonBall.subscribe(
    tracking,
    SCOPE,
    "flight-details",
    (c, s, e, data: any & SearchQueryAPI & { flight: FlightCombination }) => {
      setUtagDataForFlight(data);
      triggerPageView("flug_pt3_selected");
    }
  );

  /**
   * Triggered on Click/Select on a flight to continue to checkout page (flug_bu1)
   * Button is "Angebot Wählen"
   * previous name was "FlightSelect"
   */
  tuiCottonBall.subscribe(tracking, SCOPE, "select-flight", (c, s, e, data: { flight: FlightCombination }) => {
    // triggerEvent("flightSelect", createFlightSummaryStr(data));
    setUtagDataForFlight(data); // might need a separate function instead of hacking this.
    const link_action = data.flight.destination; // no array in flight select
    const link_label = moment(data.flight.outbound.departureTime).format("YYYY-MM"); // format YYYY-MM
    const link_category = "FlightSelect";
    const link_name = "";
    extendUtagData({ link_label });
    triggerEvent(link_action, link_name, link_category);
  });

  /**
   * NBSO-2931: Triggered when the flex flight bar is shown in search results component.
   */
  tuiCottonBall.subscribe(tracking, SCOPE, "flex-flights-shown", (c, s, e, data: any) => {
    const link_action = "Show";
    const link_label = data.alternativeFlexFlightDates; // number of alternative flex flights dates, from 0 to 4
    const link_category = "sparkalender";
    const link_name = "";
    extendUtagData({ link_label });
    triggerEvent(link_action, link_name, link_category, "feature", true);
  });

  /**
   * NBSO-2931: Triggered when a flex flight element is clicked.
   */
  tuiCottonBall.subscribe(tracking, SCOPE, "flex-flight-select", (c, s, e, data: any) => {
    const link_action = "Klick";
    const link_label = data.offsetInDays + " Tag(e)";
    const link_category = "sparkalender";
    const link_name = data.priceChange + "%";
    extendUtagData({ link_label });
    triggerEvent(link_action, link_name, link_category);
  });

  /**
   * NBSO-2594: Triggered when a user chnages a filter element.
   */
  tuiCottonBall.subscribe(tracking, SCOPE, "filter-used", (c, s, e, data: any) => {
    if (data.element === "checkbox") {
      const link_action = data.action;
      const link_name = "";
      const link_category = "FilterUsed";
      let link_label = data.value;
      if (data.name === "rebookable") link_label = "Flexible rebook";
      if (data.name === "baggageIncludedOnly") link_label = "Baggage included";
      extendUtagData({ link_label });
      triggerEvent(link_action, link_name, link_category);
    }

    if (data.element === "carrier-only-select") {
      const link_action = data.action;
      const link_name = "";
      const link_category = "FilterUsed";
      const link_label = data.value;
      extendUtagData({ link_label });
      triggerEvent(link_action, link_name, link_category);
    }

    if (data.element === "radio-btn") {
      const link_action = "select";
      const link_name = "";
      const link_category = "FilterUsed";

      let link_label = "All Flight";
      if (parseInt(data.value) === 0) link_label = "Direct Flight";
      if (parseInt(data.value) === 1) link_label = "One Stop max";
      if (parseInt(data.value) === 2) link_label = "Two Stop max";

      extendUtagData({ link_label });
      triggerEvent(link_action, link_name, link_category);
    }

    if (data.element === "slider") {
      let link_action = "unknown";
      const link_name = "";
      const link_category = "FilterUsed";
      let link_label = "unknown";

      if (data.name === "outboundTimeRange") {
        if (data.changedValue === "begin") {
          link_action = "DepartureTimeBeginOutbound";
          link_label = timestampToDayStatus(data.minValueSelect);
        } else {
          link_action = "DepartureTimeEndOutbound";
          link_label = timestampToDayStatus(data.maxValueSelect);
        }
      }

      if (data.name === "inboundTimeRange") {
        if (data.changedValue === "begin") {
          link_action = "DepartureTimeBeginInbound";
          link_label = timestampToDayStatus(data.minValueSelect);
        } else {
          link_action = "DepartureTimeEndInbound";
          link_label = timestampToDayStatus(data.maxValueSelect);
        }
      }

      if (data.name === "outboundTravelTimeRange") {
        link_action = "DurationOutbound";
        link_label = rangeNumberStatus(data.minValue, data.maxValue, data.minValueSelect);
      }

      if (data.name === "inboundTravelTimeRange") {
        link_action = "DurationInbound";
        link_label = rangeNumberStatus(data.minValue, data.maxValue, data.minValueSelect);
      }

      if (data.name === "priceRange") {
        if (data.changedValue === "begin") {
          link_label = rangeNumberStatus(data.minValue, data.maxValue, data.minValueSelect);
          link_action = "PriceMinValue";
        } else {
          link_label = rangeNumberStatus(data.minValue, data.maxValue, data.maxValueSelect);
          link_action = "PriceMaxValue";

        }
      }
      extendUtagData({ link_label });
      triggerEvent(link_action, link_name, link_category);
    }

    if (data.element === "button" && data.name === "reset") {
      extendUtagData({ link_label: "FilterReset" });
      triggerEvent("select", "", "FilterUsed");
    }

  });

  /**
   * Returns for a given timestamp one of the following strings: Night, Morning, Noon, Afternoon, Evening
   * e.g. a date with a time of 2pm would return the string "Noon"
   * @param timestamp Unix timestamp, e.g. 1631953500000
   */
  function timestampToDayStatus(timestamp: number): string {
    const time = new Date(timestamp);
    let resultStr = "unknown";
    const decimalHours = time.getHours() + (time.getMinutes() * (1/60));
    if (decimalHours > 0 && decimalHours <= 5) resultStr = "Night";
    if (decimalHours > 5 && decimalHours <= 9) resultStr = "Morning";
    if (decimalHours > 9 && decimalHours <= 14) resultStr = "Noon";
    if (decimalHours > 14 && decimalHours <= 19) resultStr = "Afternoon";
    if (decimalHours > 19 && decimalHours <= 24) resultStr = "Evening";
    return resultStr;
  }

  /**
   * Returns for a given number-range and selected-number, one of the following strings:
   * MinValue, MinArea, MidArea, MaxArea, MaxValue
   */
  function rangeNumberStatus(minValue: number, maxValue: number, selectValue: number): string {
    const range = maxValue - minValue;
    if (selectValue === minValue) return "MinValue";
    if (selectValue < minValue + (1 / 3) * range) return "MinArea";
    if (selectValue < minValue + (2 / 3) * range) return "MidArea";
    if (selectValue < maxValue) return "MaxArea";
    if (selectValue === maxValue) return "MaxValue";
    return "unknown";
  }
}