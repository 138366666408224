import setupTracking from "../tracking";
import { urlHasValidParameters } from "../helpers/url-params";

export let isTestSession = false;

export function setupGlobal() {
  setupPolyfill();
  // setupCottonBallChecker();
  setupTracking();

  const [isValid, params] = urlHasValidParameters();
  if (params.test === "1" || params.test === 1) {
    isTestSession = true;
  }
}

const polyfillUrl =
  "https://polyfill.io/v3/polyfill.min.js?features=default%2CArray.prototype.flat%2CArray.prototype.includes";
function setupPolyfill() {
  if (Array.prototype.hasOwnProperty("flat") === false) {
    const polyfillScript = document.createElement("script");
    polyfillScript.src = polyfillUrl;
    document.head.append(polyfillScript);
  }
}

function setupCottonBallChecker() {
  if (typeof tuiCottonBall === "undefined") {
    console.error("tuiCottonBall library is not loaded. Using development monkey-patched functions.");
    window.tuiCottonBall = {
      publish: (...args: any[]) => {
        console.log("tuiCottonBall publish", utag.data);
      },
      subscribe: (...args: any[]) => {
        console.log("tuiCottonBall subscribe", utag.data);
      },
      broadcast: (...args: any[]) => console.log("tuiCottonBall broadcast", args),
    } as typeof tuiCottonBall;
  }
}