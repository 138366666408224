// import "tracking.js";
// import "page-interaction.ts";

import { setupGlobal } from "./globals";
import { setupSearchFormPage } from "./pages/search-form-page";
import { setupSearchResultsPage } from "./pages/search-results-page";
import { waitCottonBallReady } from "./helpers/waitCottonBallReady";
import { setupCheckoutPage } from "./pages/checkout-page";
import { setupConfirmationPage } from "./pages/confirmation-page";
import { setupPaymentPage } from "./pages/payment-page";

(function () {
  document.addEventListener("DOMContentLoaded", (event) => {
    waitCottonBallReady().then(() => {
      console.log(
        "%cTFM Integration layer init",
        "background-color: blue; font-size: 16px; padding: 3px; color: white;"
      );
      setupGlobal();

      if (!window.tfm) {
        console.error("TFM is not properly configured. Using default value...");
        window.tfm = {
          page: "SearchResultsPage",
        };
      }
      console.log(window.tfm);
      // flug
      switch (window.tfm.page) {
        case "SearchResultsPage":
          setupSearchResultsPage();
          break;
        case "SearchFormPage":
          setupSearchFormPage();
          break;
        case "CheckoutPage":
          setupCheckoutPage();
          break;
        case "PaymentPage":
          setupPaymentPage();
          break;
        case "ConfirmationPage":
          setupConfirmationPage();
          break;
        default:
          break;
      }
      // flugauswahl
    });
  });
})();
