import moment from "moment";
import { FlightCombination, SearchQueryAPI } from "@tui-internal/tfm-types-and-helpers";
import { triggerEvent, setUtagDataForFlight, extendUtagData, triggerPageView } from "./helper";
import { booking, tracking } from "../helpers/names";
import { SCOPE } from "./tfm";
import setupTfaSsrTracking from "./tfa-ssr";

/**
 * Slowly migrating some tracking events to here.
 */
export function setupBU1Tracking() {
  // Triggered on BU1, when land on page

  tuiCottonBall.subscribe(
    tracking,
    SCOPE,
    "flight-checkout-page-view-tracking",
    (c, s, e, data: any & SearchQueryAPI & { flight: FlightCombination }) => {

      setUtagDataForFlight(data);
      console.log("bu1 page view", data);
      extendUtagData({
        flightBasePrice: data.flight?.totalPrice,
        tui_tipp: data.flight?.promoted,
        top_deal: data.flight?.topDeal,
        cabin_class: data.flight?.cabinClasses?.toString(),
        flight_source: data.source,
        destination_country: data?.destination_country,
      });
      triggerPageView("flug_bu1");
    }
  );

  // This particular one ist triggered after "Select"/Angebot wählen is clicked, you land on bu1
  tuiCottonBall.subscribe(
    booking,
    SCOPE,
    "flight-checkout-price-update",
    (c, s, e, data: any & SearchQueryAPI & { flight: FlightCombination; priceDiff: number }) => {
      setUtagDataForFlight(data);
      const link_category = "FlightPriceUpdateSelect";
      let link_action = data.destination;
      if (Array.isArray(data.destination)) {
        link_action = data.destination.join(","); // or data.destination.toString()
      }
      const link_label = moment(data.depDate, "YYYY-MM-DD").format("YYYY-MM"); // format YYYY-MM
      extendUtagData({ link_label, nbso_price_udpate: data.priceDiff });
      triggerEvent(link_action, "", link_category, "feature", true);
    }
  );

  // Triggered on BU1, after click on "Kostenpflichtig buchen" and when price changed during booking process
  // Old messaging system, but still used!!. Need to modernize sometime.
  tuiCottonBall.subscribe("tfm", "*", "PriceUpdateBooking.", (c, s, e, data) => {
    setUtagDataForFlight(data);
    extendUtagData({ nbso_price_udpate: data.priceDiffPercentage });
    triggerEvent("flightPriceUpdateBooking", "nbso_price_update=" + data.priceDiffPercentage);
  });

  tuiCottonBall.subscribe(booking, SCOPE, "flight-checkout-payment-change", (c, s, e, payload: string) => {
    const data = JSON.parse(payload) as { paymentType: string; flightCombination: FlightCombination };
    const today = new Date();
    const startDate = new Date(data.flightCombination.outbound.departureTime);
    const maturity = Math.floor((startDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    utag.link({
      tealium_event: "link",
      link_type: "feature",
      link_category: "PaymentSelect",
      link_action: data.paymentType,
      link_label: "" + maturity, // -- Vorausbuchungsfrist wg. SEPA-Ausschluss
      page_name: "flug_bu1",
      page_module: "flug_nbso", // -- fester Wert
    });
  });

  /**
   * NBSO-3173: voucher tracking
   */
  function getVoucherLabelName(stringLength: Number) {
    if (stringLength === 16) {
      return "Marketing-bu1";
    } else if (stringLength === 6) {
      return "Service-bu1";
    }
    return "Sonstige-bu1";
  }

  function trackVoucherAction(data: any, action: string) {
    if (!data.voucherInputLength) return;
    utag.link({
      link_category: "Voucher",
      link_action: action,
      link_label: getVoucherLabelName(data.voucherInputLength),
      link_type: "feature",
    });
  }
  tuiCottonBall.subscribe(booking, SCOPE, "voucher-check", (c, s, e, data: any) => trackVoucherAction(data, "Usage"));
  tuiCottonBall.subscribe(booking, SCOPE, "voucher-error", (c, s, e, data: any) => trackVoucherAction(data, "Error"));
  tuiCottonBall.subscribe(booking, SCOPE, "voucher-delete", (c, s, e, data: any) => trackVoucherAction(data, "Delete"));

  tuiCottonBall.subscribe(booking, SCOPE, "track-event", (c, s, e, data: any) =>{
    utag.link({
      link_category: data.category,
      link_action: data.action,
      link_label: data.label,
      link_type: data.type,
      link_name: data.name,
    });
  });

  setupTfaSsrTracking();
}