import { form, tracking, booking } from "../helpers/names";
import { triggerLink, triggerEvent, setUtagDataForFlight, extendUtagData, triggerPageView } from "./helper";
import { extendUtagDataTFA } from "./tfa";
import moment from "moment";

export const SCOPE = "*";

/**
 * Setup TFM Tracking
 * for now, only for flug_home and flug_pt3. Need to work on bu1 and bu3 soon, after moving out of mojo.
 * Names explanation
 * Order:
 * 1. flug_home => start point
 * 2. flug_pt3 => search results page
 * 3. flug_bu1 => checkout page (sometime called pricing page as well)
 * 4. flug_bu3 => confirmation page (after booking)
 */
export default function setupTfmGlobalTracking() {
  // Funnel tracking
  // trackData("page_module", "flug_nbso", false, false);
  console.log("setupTfmTracking");
  utag_data.page_module = "flug_nbso";

  /**
   * GLOBAL TRACKING
   * i.e. in flug_home and flug_pt3, also flug_bu1 and flug_bu3
   */

  // prev name: a link has been clicked.
  tuiCottonBall.subscribe(tracking, SCOPE, "link", (c, s, e, data) => {
    if (data.action) triggerLink(data.action, data.name);
    else triggerLink("link clicked", data.name);
  });

  // prev name: a button has been clicked.
  tuiCottonBall.subscribe(tracking, SCOPE, "button", (c, s, e, data) => {
    if (data) triggerLink("button clicked", data.name);
  });

  /**
   * Not yet used:
   */
  tuiCottonBall.subscribe(tracking, SCOPE, "error", (c, s, e, data) => {
    // window.tuiCottonBall.cuddleMeIf("tfm", "*", "an error occured.", (c, s, e, data) => {
    //   if (data) trackEvent("feature", data.category, "error occured", data.value);
    // });
    extendUtagData({ error: data.error });
    triggerEvent("error", "error", "error");
  });

  tuiCottonBall.subscribe("tfm", "*", "an error occured.", (c, s, e, data) => {
    let link_category = "unknown";
    let link_label = "unknown";
    if(data.event) {
      link_category = data.event.type;
      link_label = data.event.value;
    } 
    utag.link({
      tealium_event: "link",
      link_type: "feature",
      link_category,
      link_action: "error occured",
      link_label,
      page_name: "flug_bu1",
      page_module: "flug_nbso",
    });
  });

  tuiCottonBall.subscribe("tfm", "*", "PaymentError.", (c, s, e, data) => {
    utag.link({
      tealium_event: "link",
      link_type: "feature",
      link_category: "Error",
      link_action: "Payment",
      link_label: data.errorMessage + "sId:" + data.sessionId,
      page_name: "flug_bu1",
      page_module: "flug_nbso",
    });
  });

  tuiCottonBall.subscribe("tfm", "*", "PaymentEvent.", (c, s, e, data) => {
    utag.link({
      tealium_event: "link",
      link_type: "feature",
      link_category: "PaymentEvent",
      link_action: data.message,
      link_label: "sId:" + data.sessionId,
      page_name: "flug_bu1",
      page_module: "flug_nbso",
    });
  });

  /**
   * Only used in flug_home and flug_pt3, but can be considered global
   */
  tuiCottonBall.subscribe(tracking, "*", "search", (c, s, e, data /*: SearchQuery */) => {
    setUtagDataForFlight(data); // might need a separate function instead of hacking this.
    let link_action = data.destination;
    if (Array.isArray(data.destination)) {
      link_action = data.destination.join(","); // or data.destination.toString()
    }
    // const link_name = createFlightSummaryStr(data)
    const link_label = moment(data.depDate, "YYYY-MM-DD").format("YYYY-MM"); // format YYYY-MM
    const link_category = "FlightSearch";
    const link_name = "";
    extendUtagData({
      link_label,
      nonInteraction: false,
      tealium_event: "link",
      page_name: window.utag_data.page_name,
    });
    triggerEvent(link_action, link_name, link_category);
  });
}