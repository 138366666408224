import moment from "moment";
import { FlightCombination, SearchQueryAPI } from "@tui-internal/tfm-types-and-helpers";

export function tealiumStub() {
  if (typeof utag === "undefined") {
    console.error("Utag library is not loaded. Using development monkey-patched functions.");
    window.utag = {
      data: {},
      link: (obj: any = {}) => {
        window.utag.data = { ...window.utag.data, ...JSON.parse(JSON.stringify(obj)) };
        console.log("utag link called; utag.data:", utag.data);
      },
      view: (obj: any = {}) => {
        window.utag.data = { ...window.utag.data, ...JSON.parse(JSON.stringify(obj)) };
        console.log("utag view called; utag.data:", utag.data);
      },
    } as typeof utag;
  }
}

// consideration: do not use the compelte utag_data, but only TFM scoped utag_data.
// i.e. make one utag_data_tfm as variable here in this file, and only use or extend this variable.
// reason: utag_data variable is full of noise from other comps. If we want to trigger each utag.view or link with
// multiple variable, we might as well only trigger tags that we know we created.
export let utag_data_tfm: { [k: string]: any } = { page_module: "flug_nbso" };

export function extendUtagData(data: {}) {
  utag_data_tfm = { ...utag_data_tfm, ...JSON.parse(JSON.stringify(data)) };
  // might make problem if its not deep enough? or tealium already puts a limit on depth limit?
  // variants: Object.assign(utag_data, data);
}

export function triggerPageView(pageName: string) {
  utag.view({ ...utag_data_tfm, page_name: pageName });
}

/**
 * Not really used, every link is different according to tracking / peter.
 * This one is acually just like triggerEvent with link_categry = event
 */
export function triggerLink(link_action: string, link_name: string, link_category = "link", link_type = "feature") {
  triggerEvent(link_action, link_name, link_category, link_type);
}

/**
 * Not always for event. Can be used for something else as well, like link_category = "Price update"
 * The value for this function is that it extends the utag_data_tfm
 * If utag_data_tfm is not needed, just call utag.link directly.
 * @param link_action
 * @param link_name
 * @param link_category
 * @param link_type
 */
export function triggerEvent(
  link_action: string,
  link_name: string,
  link_category = "event",
  link_type = "feature",
  nonInteraction = false
) {
  // set nonInteraction "per Event" basis, do not set it in utag_data_tfm
  utag.link({ ...utag_data_tfm, link_category, link_action, link_name, link_type, nonInteraction });
}

export function setUtagDataForFlight(
  // this is wrong - to have SearchQueryAPI AND FlightCombination as type. Fix when you have time.
  data: Partial<
    SearchQueryAPI & { flight: FlightCombination } & /**
     * Relict properties from old angular; not used anymore
     */ {
      product_id: string;
      order_id: string;
      tui_tipp: boolean;
      product_type: string;
      product_quantity: string | number;
      maturity: string | number;
      duration: string | number;
    }
  >
) {
  // make this an unblocking call
  try {
    extendUtagData({ avail_operator: "TUI" });
    extendUtagData({ product_type: "flight" });
    extendUtagData({ flex_check: "N" });
    if (data.flex) extendUtagData({ flex_check: "Y" });

    // using comma separated instead of array, although tealium understands array, google analytics does not.
    if (data.origin) {
      let origin = data.origin;
      if (Array.isArray(origin)) {
        origin = origin.join(","); // could also do with toString()
      }
      extendUtagData({ outbound_departure_airport: origin });
      // trackData("departure_airport", data.origins);
    }

    if (data.destination) {
      let dest = data.destination;
      if (Array.isArray(dest)) {
        dest = dest.join(","); // could also do with toString()
      }
      extendUtagData({ outbound_destination_airport: dest });
      // trackData("arrival_airport", data.destinations);
    }

    if (data.depDate) extendUtagData({ date_departure: data.depDate });
    // extendUtagData({ date_range_departure_searched: data.depDate });
    // trackData("date_range_departure_searched", data.startDate);
    if (data.retDate) extendUtagData({ date_return: data.retDate });
    // if (data.retDate) extendUtagData({ date_range_return_searched: data.retDate });
    if (data.product_id) extendUtagData({ product_id: data.product_id });
    if (data.order_id) extendUtagData({ order_id: data.order_id });

    if (data.product_type) extendUtagData({ product_type: data.product_type });

    if (data.maturity) {
      // extendUtagData({ days_to_departure: data.maturity })
      extendUtagData({ days_to_departure: data.maturity });
    } else if (data.depDate) {
      let depDate = moment(data.depDate, "YYYY-MM-DD").toDate().getTime();
      let today = moment().toDate().getTime();
      let delta = depDate - today;
      let maturity = Math.ceil(moment.duration(delta).asDays());
      extendUtagData({ maturity });
    }

    if (data.duration) {
      // extendUtagData({ days_travel_duration_chosen: data.duration });
      extendUtagData({ duration: data.duration });
    } else if (data.retDate) {
      let depDate = moment(data.depDate, "YYYY-MM-DD").toDate().getTime();
      let retDate = moment(data.retDate, "YYYY-MM-DD").toDate().getTime();
      let delta = retDate - depDate;
      let duration = moment.duration(delta).asDays();
      // since both date has no time, delta will have always clear integer value
      extendUtagData({ duration });
    }

    let adultCount: number = 1;
    let childCount = 0;
    let infantCount = 0;

    if (data?.flight?.query) {
      adultCount = data.flight.query?.adult || 1;
      childCount = data.flight.query?.child || 0;
      infantCount = data.flight.query?.infant || 0;

      extendUtagData({
        number_of_travellers_adults: adultCount,
        number_of_travellers_children: childCount,
        number_of_travellers_infants: infantCount,
      });

      const productQuantityCounted = adultCount + childCount + infantCount;

      if (data.product_quantity) {
        extendUtagData({
          product_quantity: data.product_quantity,
          number_of_travellers_total: data.product_quantity,
        });
      } else if (productQuantityCounted >= 0) {
        extendUtagData({
          product_quantity: productQuantityCounted,
          number_of_travellers_total: productQuantityCounted,
        });
      } else {
        extendUtagData({
          product_quantity: 1,
          number_of_travellers_total: productQuantityCounted,
        });
      }
    }

    if (data.flight?.totalPrice && data.flight?.pricePerPerson) {
      extendUtagData({
        product_price: data.flight.pricePerPerson, // price per product == price per adult
        product_price_adult_per_pax: data.flight.pricePerPerson,
        product_totalprice: data.flight.totalPrice,
      });
    }
    if (data.flight?.pricePerChild) extendUtagData({ product_price_children: data.flight.pricePerChild });
    if (data.flight?.pricePerInfant) extendUtagData({ product_price_infants: data.flight.pricePerInfant });

    // This is a bit legacy code, might not be used at all. (And also this could potentially overwrite data.flight infos)
    // commenting out for now - if bugs exists, we might need to fix on the component instead of here.
    // Component should send flight as FlightCombination
    // if (data.product_price) extendUtagData({ product_price: data.product_price });
    // if (data.product_totalprice) extendUtagData({ product_price: data.product_totalprice });
    // if (data.product_price_adult_per_pax) extendUtagData({ product_price: data.product_price_adult_per_pax });
    // if (data.bruttoPriceAdult) extendUtagData({ product_price_adults: data.bruttoPriceAdult });
    // if (data.bruttoPriceChild) extendUtagData({ product_price_children: data.bruttoPriceChild });
    // if (data.bruttoPriceInfant) extendUtagData({ product_price_infants: data.bruttoPriceInfant });

    if (data.flight && data.flight.outbound) {
      const outbound_airlines_raw = data.flight.outbound.carriers;
      const outbound_airlines = Array.isArray(outbound_airlines_raw)
        ? outbound_airlines_raw.join(",")
        : outbound_airlines_raw;
      extendUtagData({ outbound_airlines });
      if (Array.isArray(data.flight.outbound.segments)) {
        const outbound_flight_numbers = data.flight.outbound.segments.map((s: any) => s.flightNumber).join(",");
        extendUtagData({ outbound_flight_numbers });
      }
    }
    if (data.flight && data.flight.inbound) {
      const inbound_airlines_raw = data.flight.inbound.carriers;
      const inbound_airlines = Array.isArray(inbound_airlines_raw)
        ? inbound_airlines_raw.join(",")
        : inbound_airlines_raw;
      extendUtagData({ inbound_airlines });
      if (Array.isArray(data.flight.inbound.segments)) {
        const inbound_flight_numbers = data.flight.inbound.segments.map((s: any) => s.flightNumber).join(",");
        extendUtagData({ inbound_flight_numbers });
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export function createFlightSummaryStr(data: {
  origin: string;
  destination: string;
  depDate?: string;
  retDate?: string;
  depCarriers?: string[];
  retCarriers?: string[];
  journeyType?: string;
}): string {
  // let str = [data.origins].flat().join(",") + "-" + [data.destinations].flat().join(",");
  let str = `${data.origin}-${data.destination}_${data.depDate}`;
  if (data.journeyType === "roundtrip" || data.retDate) str = `${str}-${data.retDate}`;
  if (data.depCarriers) {
    str = `${str}_${data.depCarriers.join("-")}`;
  }

  if (data.journeyType === "roundtrip" || data.retDate) {
    str = `${str}_${data.destination}-${data.origin}`;
    str = `${str}_${data.retDate}-${data.depDate}`;
    if (data.retCarriers) {
      str = `${str}_${data.retCarriers.join("-")}`;
    }
  }
  return str;
}
