import { booking, tracking } from "../helpers/names";
import { getURLParamsFromLegacyURL, isLegacyURL, transformUrl } from "../helpers/url-params";
import { waitTealiumReady } from "../helpers/waitTealiumReady";
// import { urlHasValidParameters } from "../helpers/url-params";
// import { isTestSession } from "../globals";
import { setupBU1Tracking } from "../tracking/page-bu1";
import { SCOPE } from "../tracking/tfm";

export function setupCheckoutPage() {
  // triggerPageView("flug_bu1"); // trigger only when flightCombination is loaded from inside component
  waitTealiumReady().then(() => {
    setupBU1Tracking();
  });

  tuiCottonBall.subscribe(booking, SCOPE, "flight-checkout-page-view", (c, s, e, data: any) => {
    const titleTag = document.head.querySelector("title");
    if (titleTag?.innerHTML) {
      const brandLabel = transformUrl(window.origin);
      titleTag.innerHTML = `Ihr Flug ${data.origin} ↔ ${data.destination} ist verfügbar - ${brandLabel}`;
    }
    tuiCottonBall.publish(tracking, SCOPE, "flight-checkout-page-view-tracking", data);
  });

  let urlParams: URLSearchParams | undefined = undefined;
  if (isLegacyURL(window.location)) {
    urlParams = getURLParamsFromLegacyURL(window.location).searchParams;
  }

  if (!isLegacyURL(window.location)) {
    urlParams = new URLSearchParams(window.location.search);
  }

  let updatedDeeplinkParams = {};
  if (urlParams instanceof URLSearchParams) {

    updatedDeeplinkParams = {
      combiHash: urlParams.has("combiHash") ? urlParams.get("combiHash") : "",
      origin: urlParams.has("origin") ? urlParams.get("origin") : "",
      destination: urlParams.has("destination") ? urlParams.get("destination") : "",
    };

    if(urlParams.has("agencyId") && urlParams.has("agentId")){
      const optionalServiceChargeStr = urlParams.get("optionalServiceCharge");
      const agencyData = {
        agencyId: urlParams.get("agencyId"),
        agentId: urlParams.get("agentId"),
        optionalServiceCharge: optionalServiceChargeStr ? +(parseFloat(optionalServiceChargeStr).toFixed(2)) : 0,
      }
      updatedDeeplinkParams = {...updatedDeeplinkParams, agencyData};
    }

  }
  
  tuiCottonBall.publish("tfm", "*", "setOptions", updatedDeeplinkParams);
}