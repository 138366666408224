export function waitTealiumReady(): Promise<boolean> {
  const myTimer = (res: Function) =>
    setTimeout(() => {
      if (typeof utag === "undefined") myTimer(res);
      else res(true);
    }, 5);

  return new Promise((resolve, reject) => {
    if (typeof utag === "undefined") myTimer(resolve);
    else resolve(true);
  });
}
