import { booking } from "../helpers/names";
import { SCOPE } from "./tfm";
import { triggerLink, triggerEvent } from "./helper";

const link_category = "BagSelection";

export default function setupTfaSsrTracking() {
  tuiCottonBall.subscribe(booking, SCOPE, "bag-selected", (c, s, e, p) => {
    // trackTfaSsr(p);
    // will be tracked by close event
  });

  tuiCottonBall.subscribe(
    booking,
    SCOPE,
    "bag-tracking-click-event",
    (c, s, e, payload: { name: string; flightDestination: string; payload?: any; flightSource?: string }) => {
      if (payload.name !== "close") {
        // close will be triggered by bag-selected
        trackTfaSsrClickEvent(payload.name, payload.flightDestination);
      } else if (payload.name === "close" && payload.payload) {
        trackTfaSsrCartAddEvent({
          name: payload.name,
          flightDestination: payload.flightDestination,
          payload: payload.payload,
          flightSource: payload.flightSource,
        });
      }
    }
  );
}

export function trackTfaSsrClickEvent(name: string | "open" | "close" | "edit" | "delete", flightDestination: string) {
  /**
    "tealium_event": "link", -- konstanter Wert
    "link_type": "feature",  -- konstanter Wert
    "link_category": "BagSelection",  -- konstanter Wert
    "link_action": "<Wert entsprechend geklicktem Button, siehe nachfolgend>",
    "link_label": "<Zielflughafen Hinflug, für den die Gepäckauswahl bearbeitet wird>",
   */
  // do not use triggerLink or Event - too much info
  utag.link({
    tealium_event: "link",
    link_type: "feature",
    link_action: name,
    link_category,
    link_label: flightDestination,
  });
}

export function trackTfaSsrCartAddEvent(payload: {
  name: string;
  flightDestination: string;
  payload: { config: {}; flights: Array<{ segmentIdentifier: string; passengers: any[] }> } | any;
  flightSource?: string;
}) {
  /**
    Warenkorb-Tracking Gepäckauswahl:
    product_name=<Bezeichnung des Gepäck-Types>
    product_code=<Detailcode der ausgewählten Gepäckart >-<flightSource>
    product_type='bag-book'
    product_quantity= <Anzahl gebuchter Gepäckstücke des aktuellen Gepäckart>
    product_price=<Preis pro gebuchten Gepäckstück des aktuellen Gepäckart>
   */
  const { product_quantity, product_code, product_name, product_price } = createBaggageProductInfos(payload);

  utag.link({
    tealium_event: "link",
    link_type: "feature",
    link_action: "close",
    link_label: payload.flightDestination,
    link_category,
    product_name: product_name.join(),
    product_code: product_code.join(),
    product_type: "bag-book",
    product_quantity: "" + product_quantity,
    product_price: product_price.join(),
  });
}

export function createBaggageProductInfos(payload: {
  name: string;
  flightDestination: string;
  payload: { config: {}; flights: Array<{ segmentIdentifier: string; passengers: any[] }> } | any;
  flightSource?: string;
}) {
  let product_quantity = 0;
  let product_code: string[] = [];
  let product_name: string[] = [];
  let product_price: number[] = [];
  const flights = payload && payload.payload && payload.payload.flights;
  if (flights) {
    flights.forEach((f: any) => {
      f.passengers.forEach((passenger: any) => {
        product_quantity += passenger.selectedSsr.length;
        passenger.selectedSsr.forEach((selSsr: any) => {
          product_code.push(selSsr.ssrCode + "-" + payload.flightSource);
          product_name.push(selSsr.description);
          product_price.push(selSsr.price);
        });
      });
    });
  }

  return { product_quantity, product_code, product_name, product_price };
}