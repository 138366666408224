export function urlParams() {
  const [valid, params] = urlHasValidParameters();
  if (valid) return params;
  else return {};
}
// import { SearchQuery, PollingRequest } from "../typings/SearchQuery";
import moment, { Moment } from "moment";
import { FilterKeys, SearchQueryKeys, SearchResultsState, TrackingKeys } from "../pages/search-results-page";

export function urlHasValidParameters(): [boolean, { [key: string]: any }] {
  // could have used https://developer.mozilla.org/de/docs/Web/API/URLSearchParams
  // but doesnt work on old browsers
  const paramsReg = window.location.search.match(/(?:\?|&)([^=]+)=([^&]+)/g);
  const params: { [key: string]: any } = {};
  if (paramsReg && paramsReg.length > 0) {
    paramsReg.map((paramR) => {
      // const [_, key, value] = paramR.match(/(?:\?|&)([^=]+)=([^&]+)/);
      const matches = paramR.match(/(?:\?|&)([^=]+)=([^&]+)/);
      if (!matches) return;
      const key: string = matches[1];
      const value = matches[2];
      switch (key) {
        case "adult":
        case "child":
        case "infant":
          params[key] = +value; // convert to int, wont work with Amadeus
          break;
        case "carriers":
          params[key] = value.split(",");
          break;
        case "flex":
          params[key] = value === "1" || value === "true";
          break;
        default:
          // everything else
          params[key] = decodeURIComponent(value);
          break;
      }
    });

    // if (hasValidQuery(params as Partial<SearchQuery>)) {
    checkOrAddJourneyType(params);

    if (hasValidQuery(params)) {
      return [true, params];
    }
    // TODO else if has valid origin and destination but wrong date, shouldstill be able to fill search-form
    // search form has validation on date built-in
  }
  return [false, params]; // allow filling partial initial value
}

export function hasValidQuery(query: any) {
  if (!query.depDate) return false;
  if (moment(query.depDate) < moment().startOf("day")) return false;

  if (query.journeyType === "ROUNDTRIP") {
    if (!query.retDate) return false;
    if (moment(query.retDate) < moment(query.depDate)) return false;
  }

  return true;
}

function checkOrAddJourneyType(params: { [k: string]: any }) {
  if (!params.journeyType) {
    if (params.retDate) {
      params.journeyType = "ROUNDTRIP";
    } else {
      params.journeyType = "ONEWAY";
    }
  } else {
    params.journeyType = params.journeyType.toUpperCase();
  }
}

// URL parameters that are taken account of
let searchQueryKeys = [
  "journeyType",
  "origin",
  "destination",
  "depDate",
  "retDate",
  "adult",
  "child",
  "infant",
  "flex",
];
let filterKeys = [
  "airports",
  "baggageIncludedOnly",
  "rebookable",
  "cabinClasses",
  "carriers",
  "inboundTimeRange",
  "inboundMaxTravelTime",
  "maxStops",
  "outboundTimeRange",
  "outboundMaxTravelTime",
  "priceRange",
];
let trackingKeys = [
  "coopid",
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_term"
]

export function getStateFromUrl(params: string): SearchResultsState {
  const asObj = new URLSearchParams(params) as any; //  | SearchQueryAPI; // & FilterData;
  let search: SearchQueryKeys = {};
  let filter: FilterKeys = {};
  let tracking: TrackingKeys = {};
  searchQueryKeys.forEach((key) => {
    if (asObj.get(key)) {
      let value = asObj.get(key);
      switch (key) {
        case "adult":
        case "child":
        case "infant":
          search[key] = +value; // convert to int, wont work with Amadeus
          break;
        default:
          // everything else
          search[key] = decodeURIComponent(value);
          break;
      }
    }
  });
  checkOrAddJourneyType(search);
  filterKeys.forEach((key) => {
    if (asObj.get(key)) {
      let value = asObj.get(key);
      switch (key) {
        case "airports":
        case "carriers":
        case "cabinClasses":
          filter[key] = value.split(",");
          break;
        case "maxStops":
        case "outboundMaxTravelTime":
        case "inboundMaxTravelTime":
          filter[key] = +value;
          break;
        case "priceRange":
        case "inboundTimeRange":
        case "outboundTimeRange":
          let rangeValues = value.split(",");
          filter[key] = rangeValues.map((val: string) => +val);
          break;
        case "baggageIncludedOnly":
        case "rebookable":
          filter[key] = value === "true";
          break;
        default:
          filter[key] = decodeURIComponent(value);
          break;
      }
    }
  });
  const scrollY = asObj.get("scrollY");
  const paramTest = asObj.get("test");
  let test = false;
  if (paramTest && (paramTest === "1" || paramTest === 1 || paramTest === "true" || Boolean(paramTest))) test = true;

  trackingKeys.forEach((key) => {
    if (asObj.get(key)) tracking[key] = asObj.get(key);
  });

  return {
    search,
    filter,
    scrollY,
    test,
    tracking,
  };
}

export function getSearchQuery(data: { [k: string]: any }) {
  let result: { [k: string]: any } = {};
  searchQueryKeys.forEach((key) => {
    if (data[key]) result[key] = data[key];
  });
  return result;
}

export function getFilterData(data: { [k: string]: any }) {
  let result: { [k: string]: any } = {};
  filterKeys.forEach((key) => {
    if (data[key]) result[key] = data[key];
  });
  return result;
}

// some parts of the legacy components have # as part of the url and 
// therefore it breaks the URLSearchParams retrieval from window.location
export function isLegacyURL(location: Location) {
  return location.hash !== "";
}

export function transformUrl(inputUrl: string): string {
  if (!inputUrl) {
    return 'TUI.com';
  }

  const cleanedUrl = inputUrl.replace('https://www.', '');
  const [firstWord, restOfUrl] = cleanedUrl.split('.');
  const transformedWord = firstWord.toUpperCase();
  const resultUrl = transformedWord + '.' + restOfUrl;

  return resultUrl;
}

export function getURLParamsFromLegacyURL(location: Location) {
  const urlWithoutHash = location.hash.substring(1);
  return new URL(urlWithoutHash, location.origin);
}